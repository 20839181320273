@import '../variables';
.single-page {
  ::selection {
    background-color: lighten($lightGray, 30%);
  }
  hyphens: auto;
  @include normal-font;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $background;
  min-height: 100vh;
  overflow-x: auto;
  h3, h4 {
    @include bold-font;
  }
  p {
    white-space: normal;
  }
  h5 {
    margin-block-end: 0px;
    @include bold-font;
  }
  ul {
    margin: 0;
    margin-block-start: 0;
    padding-inline-start: 20px;
  }
  a {
    color: $black;
    &:hover {
      color: #888;
    }
  }
  .small {
    font-size: 0.83em;
    padding-right: 0.3em;
  }
  .muted {
    color: #888;
  }
  article {
    margin: 30px;
    max-width: 500px;
  }
  padding-bottom: 60px;
}

.underlined-link {
  @include underlined-font;
}