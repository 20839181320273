@mixin normal-font {
  font-family: Favorit Regular, sans-serif;
  font-weight: 300;
  font-style: normal;
  line-height: 155%;
}

@mixin underlined-font {
  font-family: Favorit Regular Lining, sans-serif;
  font-weight: 300;
  font-style: normal;
  line-height: 155%;
}

@mixin bold-font {
  font-family: Favorit Bold, sans-serif;
}
@mixin italic-font {
  font-family: Favorit Italic, sans-serif;
}

$action-bar-height: 100px;
$black: #191922;
$beige: #fffaf7;
$white: #fff;
$background: #f3f3f3;
$darkGray: #454549;
$lightGray: lighten($darkGray, 32)