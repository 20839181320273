@import '../../variables';
$action-bar-desktop-width: 200px;

::selection {
  background-color: rgba(0,0,0,0);
}
* {
  @include normal-font;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: $black;
}
.action-bar, .single-page-bar {
  text-transform: capitalize;
  position: fixed;
  bottom: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: $action-bar-height;
  width: 100%;

  &--menu-list {

    margin: 0;
    padding-inline-start: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    &--item {
      padding-left: 20px;
      padding-right: 5px;
      padding-bottom: 28px;
      a {
        color: $black;
        text-decoration: none;
      }
    }
  }
  &--mobile-menu {
    position: absolute;
    z-index: 999;
    top: -100px;
    left: -56px;
    transform: rotate(90deg);
    margin: 0;
    padding-inline-start: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    opacity: 1;
    transition: all 0.2s ease;
    &--item {
      padding-left: 20px;
      padding-bottom: 20px;
      a {
        color: $black;
      }
    }
  }
  .button-box {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: all 0.3s ease;
    margin-bottom: 15px;
    .logo {
      transition: all 0.1s ease;
    }

    .vertical-text {
      position: relative;
      text-transform: lowercase;
      top: -60px;
      font-size: 10pt;
      right: 4%;
      color: $beige;
      transform: rotate(90deg);
      transition: all 0.1s ease;
      letter-spacing: 0.9pt;
    }
    &:hover {
      .logo {
        //transform: scale(1.1);
      }
      .vertical-text {
        top: -53px;
      }
    }
    .logo {
      width: 45px;
      height: 42px;
      margin-top: 3px;
      background: url(../../assets/icons/handshake.svg) no-repeat center;
    }
  }
  .circle {
    background-color: $background;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    margin-bottom: 20px;
    //box-shadow: 0 19px 38px rgba(0,0,0,0.20), 0 15px 12px rgba(0,0,0,0.16);
    transition: all 0.1s ease;

    .animation-bar {
      position: absolute;
      bottom: 20px;
      width: 60px;
      height: 60px;
      background-color: lighten($background, 0.5);
      border-radius: 60px;
      z-index: -999;
      transition: height 0.1s ease;
    }
  }
  .button {
    //background-color: $background;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3%;
    margin-bottom: 2px;
    padding: 5px 10px;
    padding-top: 6px;
    border: 2px solid lighten($darkGray, 20);
    a {
      letter-spacing: 0.3px;
    color: lighten($darkGray, 5);
    }
    //box-shadow: 0 19px 38px rgba(0,0,0,0.20), 0 15px 12px rgba(0,0,0,0.16);
    transition: all 0.1s ease;
    //background-color: $background;
    text-transform: uppercase;
    .animation-bar {
      position: absolute;
      bottom: 20px;
      width: 60px;
      height: 60px;
      background-color: lighten($background, 0.5);
      border-radius: 60px;
      z-index: -999;
      transition: height 0.1s ease;
    }
  }
  .scale-on-hover {
    &:hover{
      transform: scale(1.1);
    }
  }
}


.mobile-menu {
  display: none;
  padding-left: 20px;
  padding-right: 20px;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
}


//.mobile-extra-margin {
//  margin-bottom: 35px;
//}
@media screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
  .hide-on-desktop {
    display: flex;
  }
  .mobile-menu {
    display: flex;
  }
}

@media screen and (min-width: 600px) {
  .action-bar {
    width: $action-bar-desktop-width;
    left: calc((100vw/2) - (#{$action-bar-desktop-width} / 2));
  }
  .hide-on-desktop {
    display: none;
  }
  .hide-on-mobile {
    display: flex;
  }
  .action-bar--menu-list--item {
    padding-bottom: 38px;
  }
}

.single-page-bar--wrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}
.single-page-bar {
  text-transform: capitalize;
  z-index: 9;
  display: flex;
  background-color: $background;
  align-items: flex-end;
  height: 60px;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  padding: 0 30px;
  @media screen and (min-width: 600px) {
    padding: 0;
  }
  .button-box {
    display: flex;
    margin: 0;
    padding: 15px 0;
    justify-content: center;
    flex-direction: column;
    transition: all 0.3s ease;
  }
  .back-button {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    width: 50px;
    height: 50px;
    justify-content: flex-end;
    align-items: flex-end;
    a {
      color: $black;
    }
  }
}

.blinking {
  animation: blink 1.5s ease-in-out infinite;
  color: $lightGray;
  margin-bottom: 10px;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}