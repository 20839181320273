@import '../../variables';

.loading-screen {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $background;
  color: $beige;
  &--text {
    animation: blink-animation 1s steps(5, start) infinite;
    .logo {
      animation: blink-animation 1s infinite;
      width: 45px;
      height: 42px;
      color: $lightGray;
      margin-top: 3px;
      background: url(../../assets/icons/greyHand_big.svg) no-repeat center;
    }
  }
}

.dialog-enter {
  opacity: 0.01;
  transform: scale(1.1);
}

.dialog-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}

.dialog-exit {
  opacity: 1;
  transform: scale(1);
}

.dialog-exit-active {
  opacity: 0.01;
  transform: scale(1.1);
  transition: all 300ms;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
