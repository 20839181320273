@import '../../variables';

.burger {
  //margin-bottom: 45px;
  width: 40px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.2s;
  :nth-child(2) {
    margin-top: 5px;
  }
  &--bar {
    transition: all 0.2s ease;
    height: 2px;
    width: 30px;
    background-color: $black;
  }
}

.isActive {
  transform: translateX(3.5px);
  :nth-child(1) {
    transform: rotate(45deg) translateY(5px);
  }
  :nth-child(2) {
    transform: rotate(-45deg) translateY(-5px);
  }
}