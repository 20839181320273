@import './variables.scss';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Favorit Regular';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/FavoritStd-Regular.woff') format('woff'),  /* Modern Browsers */
  url('./assets/fonts/FavoritStd-Regular.woff2') format('woff2');  /* Super Modern Browsers */
}

@font-face {
  font-family: 'Favorit Bold';
  src: url('./assets/fonts/FavoritStd-Bold.woff') format('woff'),  /* Modern Browsers */
  url('./assets/fonts/FavoritStd-Bold.woff2') format('woff2');  /* Super Modern Browsers */
}

@font-face {
  font-family: 'Favorit Italic';
  src: url('./assets/fonts/FavoritStd-Italic.woff') format('woff'),  /* Modern Browsers */
  url('./assets/fonts/FavoritStd-Italic.woff2') format('woff2');  /* Super Modern Browsers */
}

@font-face {
  font-family: 'Favorit Regular Lining';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/FavoritLiningStd-Regular.woff') format('woff'),  /* Modern Browsers */
  url('./assets/fonts/FavoritLiningStd-Regular.woff2') format('woff2');  /* Super Modern Browsers */
}