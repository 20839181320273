.scene-wrapper {
  cursor: none;
  position: fixed;
  top: 0;
  left: 0;
}
.text-under-scene-wrapper {
  position: absolute;
  min-height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#babylonCanvas {
  position: absolute;
  top: 0;
  z-index: 9;
}
.call-to-action {
  z-index: 12;
  position: fixed;
  bottom: 10vh;
  width: 100%;
  span {
    animation: blink 2s linear infinite;
  }
}
@keyframes blink{
  0%{opacity: 0;}
  25%{opacity: .5;}
  50%{opacity: 1;}
  75%{opacity: .5;}
  100%{opacity: 0;}
}